import React, { Fragment, useEffect, useState } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { ANNOUNCEMENT_DATE } from "@src/constants";
import clsx from "clsx";

export function AnnouncementModal() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const displayedAnnouncementDate = new Date(localStorage.getItem("displayed_announcement_date"));
    if (!displayedAnnouncementDate.getTime()) {
      localStorage.setItem("displayed_announcement_date", ANNOUNCEMENT_DATE);
      setIsOpen(true);
    } else {
      const announcementDate = new Date(ANNOUNCEMENT_DATE);
      if (displayedAnnouncementDate < announcementDate) {
        localStorage.setItem("displayed_announcement_date", ANNOUNCEMENT_DATE);
        setIsOpen(true);
      }
    }
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 font-gothic"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-80 transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle className="py-1.5 flex w-full flex-col gap-2">
                  <div className="text-center text-xl">アップデートのお知らせ</div>
                  <div className="pb-2 text-end text-sm text-neutral-400">{ANNOUNCEMENT_DATE}</div>
                </DialogTitle>
                <div className="py-1 text-sm space-y-2">
                  <p>フィルターボタンを設置しました。</p>
                  <p>AI校正やルール辞書など指摘毎にエラーパネルを絞り込むことができます。</p>
                </div>
                <div className="mt-4">
                  <button
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    className={clsx(
                      "w-full text-white rounded border px-4 py-2 text-center text-sm font-bold bg-neutral-900 border-neutral-900",
                      "hover:bg-neutral-200 hover:border-neutral-200 hover:text-neutral-900"
                    )}
                  >
                    閉じる
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
