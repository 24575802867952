import * as React from "react";
import "../style.css";
import TextInsertion from "@src/taskpane/components/TextInsertion";
import { Login } from "@src/taskpane/login/login";

const App = () => {
  const isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));

  return <div className="text-sm font-gothic">{isAuthenticated ? <TextInsertion /> : <Login />}</div>;
};

export default App;
