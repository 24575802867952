import type React from "react";
import { create } from "zustand";

type Alert = {
  alertTitle: string;
  alertBody: string | React.JSX.Element;
  isLogout?: boolean;
};

type AlertStore = {
  isShowingAlert: boolean;
  alertTitle: string;
  alertBody: string | React.JSX.Element;
  isLogout: boolean;

  showAlert: (alert: Alert) => void;
  hideAlert: () => void;
};

const initialAlertState = {
  isShowingAlert: false,
  alertTitle: "",
  alertBody: "",
  isLogout: false,
};

export const useAlertStore = create<AlertStore>()((set) => ({
  ...initialAlertState,
  showAlert: (alert: Alert) => set(() => ({ ...alert, isShowingAlert: true })),
  hideAlert: () => set(() => ({ ...initialAlertState })),
}));
