import React, { useState } from "react";
import { useUserStore } from "@src/store/user-store";
import { ConfirmModal } from "@src/taskpane/components/modal/confirm-modal";
import { logout } from "@src/utils/logout";
import { MdLogout } from "react-icons/md";

export function LogoutButton() {
  const [isOpen, setIsOpen] = useState(false);

  const serviceType = useUserStore((state) => state.serviceType);
  const clearUser = useUserStore((state) => state.clearUser);

  return (
    <>
      <button onClick={() => setIsOpen(true)}>
        <MdLogout size={24} className="text-neutral-900 hover:text-neutral-500" />
      </button>
      <ConfirmModal
        isOpen={isOpen}
        onConfirm={() => {
          logout({ serviceType, clearUser });
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        confirmMessage="ログアウトしてよろしいですか？"
        executeText="ログアウトする"
        cancelText="キャンセル"
      />
    </>
  );
}
