import React, { useEffect, useMemo, useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useProofreadingStore } from "@src/store/proofreading-store";
import type { IgnoredTypolessError, TypolessError } from "@src/types/editor-response";
import { ErrorPanelType } from "@src/types/error-panel-type";
import { ProofreadingStatus } from "@src/types/proofreading-status";
import { ErrorPanel } from "./error-panel/error-panel";

export function ErrorPanels({ errors }: { errors: TypolessError[] }) {
  const [editorConfigHeaderHeight, setEditorConfigHeaderHeight] = useState(121);

  const status = useProofreadingStore((state) => state.status);
  const revisedErrors = useProofreadingStore((state) => state.revisedErrors);
  const ignoredErrors = useProofreadingStore((state) => state.ignoredErrors);
  const errorPanelTab = useProofreadingStore((state) => state.errorPanelTab);
  const setErrorPanelTab = useProofreadingStore((state) => state.setErrorPanelTab);

  useEffect(() => {
    if (errorPanelTab === ErrorPanelType.IGNORED) {
      setEditorConfigHeaderHeight(165);
    } else {
      setEditorConfigHeaderHeight(121);
    }
  }, [errorPanelTab]);

  const IgnoredErrorsFilter = {
    ALL: { key: "all", label: "すべて" },
    TEMPORARY: { key: "temporary", label: "一時無視" },
    PERMANENTLY: { key: "permanently", label: "永続無視" },
  } as const satisfies Record<string, { key: string; label: string }>;
  type IgnoredErrorsFilter = (typeof IgnoredErrorsFilter)[keyof typeof IgnoredErrorsFilter]["key"];
  const [ignoredErrorsFilter, setIgnoredErrorsFilter] = useState<IgnoredErrorsFilter>(IgnoredErrorsFilter.ALL.key);

  const unfixedMessage = useMemo(() => {
    if (status === ProofreadingStatus.INIT) {
      return "校正結果がこちらに表示されます";
    }
    if (errors.length <= 0) {
      if (status === ProofreadingStatus.LOADING) {
        return "校正中…";
      }
      return "校正箇所はありません";
    }
    return "";
  }, [status, errors]);

  const fixedMessage = useMemo(() => {
    if (revisedErrors.length <= 0) {
      return "修正済がこちらに表示されます";
    }
    return "";
  }, [revisedErrors]);

  const ignoredMessage = useMemo(() => {
    const temporaryErrors = ignoredErrors.filter((error) => !error.isPermanently);
    const permanentlyErrors = ignoredErrors.filter((error) => error.isPermanently);

    if (ignoredErrorsFilter === "all" && ignoredErrors.length <= 0) {
      return "無視リストにはまだなにも登録されていません";
    }
    if (ignoredErrorsFilter === "temporary" && temporaryErrors.length <= 0) {
      return "一時無視リストにはまだなにも登録されていません";
    }
    if (ignoredErrorsFilter === "permanently" && permanentlyErrors.length <= 0) {
      return "永続無視リストにはまだなにも登録されていません";
    }
    return "";
  }, [ignoredErrors, ignoredErrorsFilter]);

  const errorPanels = [
    { id: "unfixed", label: "未修正", message: unfixedMessage, type: ErrorPanelType.UNFIXED, errors: errors },
    { id: "fixed", label: "修正済", message: fixedMessage, type: ErrorPanelType.FIXED, errors: revisedErrors },
    {
      id: "ignored",
      label: "無視リスト",
      message: ignoredMessage,
      type: ErrorPanelType.IGNORED,
      errors: ignoredErrors,
    },
  ];

  const filteredErrors = ({
    type,
    errors,
  }: {
    type: ErrorPanelType;
    errors: TypolessError[] | IgnoredTypolessError[];
  }) => {
    if (type !== ErrorPanelType.IGNORED) {
      return errors;
    }
    return errors.filter((error: IgnoredTypolessError) => {
      switch (ignoredErrorsFilter) {
        case IgnoredErrorsFilter.ALL.key:
          return true;
        case IgnoredErrorsFilter.PERMANENTLY.key:
          return error.isPermanently === true;
        case IgnoredErrorsFilter.TEMPORARY.key:
          return error.isPermanently === false;
      }
    });
  };

  return (
    <TabGroup selectedIndex={errorPanelTab} onChange={setErrorPanelTab}>
      <TabList className="border-b border-b-neutral-400 text-sm flex justify-between">
        <div>
          {errorPanels.map(({ id, label }) => (
            <Tab id={id} key={id}>
              {({ selected }) => (
                <div
                  className={`${
                    selected ? "border-b-2 border-b-neutral-900 text-neutral-900 font-bold" : "text-neutral-400"
                  } w-[72px] py-1.5 hover:bg-neutral-100`}
                >
                  {label}
                </div>
              )}
            </Tab>
          ))}
        </div>
      </TabList>

      <TabPanels>
        {errorPanels.map(({ message, type, errors, id }) => (
          <TabPanel id="unfixed-panel" key={id}>
            {/* 無視リストパネル表示時のみ、上部にフィルターボタンを表示 */}
            {type === ErrorPanelType.IGNORED && (
              <div className="flex justify-between px-1 py-1.5 shadow-md">
                <div className="flex">
                  {Object.values(IgnoredErrorsFilter).map(({ key, label }) => (
                    <label key={key} className="flex cursor-pointer items-center gap-1.5 p-2">
                      <input
                        type="radio"
                        className="peer scale-125 accent-neutral-900"
                        checked={key === ignoredErrorsFilter}
                        onChange={() => setIgnoredErrorsFilter(key)}
                      />
                      <span className="text-xs text-neutral-400 peer-checked:font-semibold peer-checked:text-neutral-900">
                        {label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )}
            <div
              className="space-y-2 overflow-y-scroll p-2"
              style={{ height: `calc(100vh - ${editorConfigHeaderHeight}px)` }}
            >
              {message && <p className="p-2">{message}</p>}

              {filteredErrors({ type, errors }).map((error, key) => {
                return <ErrorPanel error={error} key={key} panelType={type} />;
              })}
            </div>
          </TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
}
