import React, { Fragment, useState } from "react";
import { Dialog, DialogPanel, Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { useAlertStore } from "@src/store/alert-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { useVisibleErrors } from "@src/store/use-visible-errors";
import { useUserStore } from "@src/store/user-store";
import { CustomButton } from "@src/taskpane/components/button/custom-button";
import { focusNextErrorPanel } from "@src/taskpane/error-panels/error-panel/focus-next-error-panel";
import type { TypolessError } from "@src/types/editor-response";
import { isWordAvailableBtoCUser } from "@src/utils/is-btoc-premium";
import { MdVisibilityOff } from "react-icons/md";

export function AsahiRuleIgnoreButton({ error }: { error: TypolessError }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const showAlert = useAlertStore((state) => state.showAlert);

  const ignoreError = useProofreadingStore((state) => state.ignoreError);
  const setSelectedError = useProofreadingStore((state) => state.setSelectedError);
  const inputTextStates = useProofreadingStore((state) => state.inputTextStates);

  const hiddenDialog = useProofreadingStore((state) => state.hiddenDialogAddPermanentlyIgnoreError);
  const setHiddenDialog = useProofreadingStore((state) => state.setHiddenDialogAddPermanentlyIgnoreError);

  const plan = useUserStore((state) => state.plan);

  const { visibleErrors } = useVisibleErrors();

  // 永続無視する
  const encodedCredentials = btoa(
    `${process.env.TYPOLESS_BASIC_AUTHORIZATION_USER_NAME}:${process.env.TYPOLESS_BASIC_AUTHORIZATION_PASSWORD}`
  );
  const ignoredAsahiRules = async (error) => {
    const res = await fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/ignored-asahi-rules/ignore-asahi-rule`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
      },
      credentials: "include",
      body: JSON.stringify({ error }),
    });
    if (!res.ok) {
      showAlert({
        alertTitle: "アプリケーションエラー",
        alertBody: "APIでエラーが生じたため、再度校正ボタンを押してください",
      });
    }
    return res;
  };

  return (
    <>
      <Popover className="relative">
        <PopoverButton>
          <MdVisibilityOff className="h-6 w-6 text-[#BDBDBD]" />
        </PopoverButton>
        <PopoverPanel className="absolute bottom-8 right-0 min-w-max rounded-lg bg-white py-2 text-sm shadow-blur-only">
          <ul>
            <li className="hover:bg-neutral-100">
              <button
                type="button"
                className="px-3 py-2"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (isWordAvailableBtoCUser({ plan }) || hiddenDialog) {
                    const res = await ignoredAsahiRules(error);
                    if (!res.ok) {
                      return;
                    }
                    ignoreError(error, true);
                  } else {
                    openModal();
                  }
                }}
              >
                永続的に無視する
              </button>
            </li>
            <li className="hover:bg-neutral-100">
              <button
                type="button"
                className="px-3 py-2"
                onClick={async (e) => {
                  e.stopPropagation();
                  ignoreError(error, false);
                  await focusNextErrorPanel({ visibleErrors, id: error.id, setSelectedError, inputTextStates });
                }}
              >
                今回は無視する
              </button>
            </li>
          </ul>
        </PopoverPanel>
      </Popover>

      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog
          className="relative z-50"
          onClose={() => {
            setHiddenDialog(false);
            closeModal();
          }}
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
          <div className="fixed inset-0 flex w-screen items-center justify-center">
            <DialogPanel className="mx-auto max-w-sm rounded-lg bg-white p-6 text-sm leading-relaxed">
              <p>組織ユーザー全員に対して永続無視が適用されます。</p>
              <p>永続無視してよろしいでしょうか？</p>

              <div className="my-4 flex items-center justify-center gap-2">
                <input
                  type="checkbox"
                  id="never-show-message-again"
                  className="scale-125"
                  checked={hiddenDialog}
                  onChange={(e) => {
                    setHiddenDialog(e.target.checked);
                  }}
                />
                <label htmlFor="never-show-message-again">今後このメッセージを表示しない</label>
              </div>

              <div className="flex flex-col gap-2">
                <CustomButton
                  type="dark"
                  text="永続無視する"
                  onClick={async () => {
                    const res = await ignoredAsahiRules(error);
                    if (!res.ok) {
                      return;
                    }
                    ignoreError(error, true);
                    await focusNextErrorPanel({ visibleErrors, id: error.id, setSelectedError, inputTextStates });
                    closeModal();
                  }}
                />
                <CustomButton
                  type="default"
                  text="キャンセル"
                  onClick={() => {
                    setHiddenDialog(false);
                    closeModal();
                  }}
                />
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
