import type { InputTextStates } from "@src/store/proofreading-store";
import { getEditorText } from "@src/taskpane/proofreading/get-editor-text";
import { diffChars } from "diff";

/**
 * 修正前後の文章から差分を取得してInputTextStatesを構築する
 *
 * @param {InputTextStates[]} inputTextStates
 * @return {Promise<InputTextStates[]>}
 */
export async function buildInputTextStates(inputTextStates: InputTextStates[]): Promise<InputTextStates[]> {
  const currentText = await getEditorText();
  const chars = inputTextStates.map(({ char }) => char).join("");
  const res = diffChars(chars, currentText);
  const periods = ["。", "\r"];

  let index = 0;
  res.forEach(function (part: { count: number; value: string; added: boolean; removed: boolean }) {
    if (part.added) {
      const parts = [...part.value];
      for (let i = 0; i < parts.length; i++) {
        inputTextStates.splice(index, 0, {
          index,
          char: parts[i],
          errorPanelIds: [],
          isProofreading: true,
        });
        index++;
      }
      return;
    }
    if (part.removed) {
      [...part.value].forEach(() => {
        inputTextStates.splice(index, 1);
      });
      if (inputTextStates[index - 1] && !periods.includes(inputTextStates[index - 1].char)) {
        inputTextStates[index - 1].isProofreading = true;
      }
      if (inputTextStates[index]) {
        inputTextStates[index].isProofreading = true;
      }
      return;
    }
    [...part.value].forEach((v) => {
      inputTextStates[index] = {
        index,
        char: v,
        errorPanelIds: inputTextStates[index].errorPanelIds,
        isProofreading: inputTextStates[index].isProofreading,
      };
      index = index + 1;
    });
    return;
  });

  for (let i = 0; i < inputTextStates.length; i++) {
    if (inputTextStates[i].isProofreading) {
      // 前の文字
      for (let j = i - 1; j >= 0; j--) {
        if (periods.includes(inputTextStates[j].char)) {
          break;
        }
        inputTextStates[j].isProofreading = true;
      }

      // 次の文字
      for (let k = i + 1; k < inputTextStates.length; k++) {
        inputTextStates[k].isProofreading = true;
        i++;
        if (periods.includes(inputTextStates[k].char)) {
          break;
        }
      }
    }
  }
  return inputTextStates;
}
