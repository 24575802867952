import React, { useMemo } from "react";
import { clsx } from "clsx";

export function CustomButton({
  type = "default",
  text,
  onClick,
  disabled = false,
  icon,
}: Readonly<{
  type?: "default" | "strong" | "dark";
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  icon?: React.ReactElement;
}>) {
  const buttonStyle = useMemo(() => {
    switch (type) {
      case "default":
        return "border-neutral-500 bg-white hover:bg-neutral-100 hover:border-neutral-100";
      case "strong":
        return "text-white border-primary-600 bg-primary-600 hover:bg-neutral-100 hover:border-neutral-100 hover:text-neutral-900";
      case "dark":
        return "text-white border-neutral-900 bg-neutral-900 hover:bg-neutral-200 hover:border-neutral-200 hover:text-neutral-900";
    }
  }, [type]);

  return (
    <button
      className={clsx(
        "rounded border px-4 py-2 text-center text-sm font-bold",
        !disabled ? buttonStyle : "text-white bg-neutral-200"
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      disabled={disabled}
    >
      {text}
      {icon && <span className="ml-2">{icon}</span>}
    </button>
  );
}
