import { useMemo } from "react";
import { useEditorSettingStore } from "@src/store/editor-setting-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { AsahiRuleSubCategory } from "@src/types/asahi-rule-category";
import { TextlintCategory } from "@src/types/textlint-category";
import { useShallow } from "zustand/react/shallow";

export function useVisibleErrors() {
  const errors = useProofreadingStore((state) => state.errors);

  const {
    isTyeErrorVisible,
    isCustomRuleErrorVisible,
    isAsahiRuleErrorVisible,
    isTextlintErrorVisible,
    isFlamingRiskErrorVisible,

    isAsahiRuleInappropriateExpressionsVisible,
    isAsahiRuleNonJoyoKanjiVisible,
    isAsahiRuleNonJoyoReadingVisible,
    isAsahiRuleCharacterFormVisible,
    isAsahiRuleMixedScriptVisible,
    isAsahiRuleKanjiAllowedVisible,
    isAsahiRuleNumericFormatVisible,
    isAsahiRuleMisuseVisible,
    isAsahiRuleIdiomaticExpressionVisible,
    isAsahiRuleCommonNameVisible,
    isAsahiRuleKatakanaCautionVisible,
    isAsahiRuleKanaCautionVisible,
    isAsahiRuleCharacterTypeCautionVisible,
    isAsahiRuleHomonymVisible,
    isAsahiRuleHonorificsCautionVisible,
    isAsahiRuleKanaReadingVisible,
    isAsahiRuleZenkakuVisible,
    isAsahiRuleInputConversionErrorVisible,
    isAsahiRuleRedundantExpressionVisible,
    isAsahiRuleOmittedCharacterVisible,
    isAsahiRulePlaceNameCautionVisible,
    isAsahiRuleProperNounCautionVisible,
    isAsahiRuleTechnicalTermVisible,

    isTextlintNoHankakuKanaVisible,
    isTextlintNoDoubledJoshiVisible,
    isTextlintNoDoubleNegativeVisible,
    isTextlintNoMixDearuDesumasuVisible,
    isTextlintSentenceLengthVisible,
    isTextlintNoUnmatchedPairVisible,
    isTextlintNoDoubledGaVisible,
    isTextlintNoNfdVisible,
    isTextlintNoInsertDroppingSaVisible,
    isTextlintNoDroppingRaVisible,
    isTextlintPreferTariTariVisible,
    isTextlintNoRedundantExpressionVisible,
    isTextlintNoSuccessiveWordVisible,
    isTextlintNoSynonymsVisible,
    isTextlintMaxTenVisible,
    isTextlintNoAbusageVisible,
    isTextlintKyoikuKanjiVisible,
    isTextlintUnnaturalAlphabetVisible,
    isTextlintUseSiUnitsVisible,
    isTextlintHiraganaKeishikimeishiVisible,
    isTextlintHiraganaFukushiVisible,
    isTextlintHiraganaHojodoushiVisible,
    isTextlintDateWeekdayMismatchVisible,
    isTextlintNoMixedPeriodVisible,
    isTextlintNoZenkakuNumbers,
  } = useEditorSettingStore(
    useShallow((state) => ({
      isTyeErrorVisible: state.isTyeErrorVisible,
      isCustomRuleErrorVisible: state.isCustomRuleErrorVisible,
      isAsahiRuleErrorVisible: state.isAsahiRuleErrorVisible,
      isFlamingRiskErrorVisible: state.isFlamingRiskErrorVisible,

      isAsahiRuleInappropriateExpressionsVisible: state.isAsahiRuleInappropriateExpressionsVisible,
      isAsahiRuleNonJoyoKanjiVisible: state.isAsahiRuleNonJoyoKanjiVisible,
      isAsahiRuleNonJoyoReadingVisible: state.isAsahiRuleNonJoyoReadingVisible,
      isAsahiRuleCharacterFormVisible: state.isAsahiRuleCharacterFormVisible,
      isAsahiRuleMixedScriptVisible: state.isAsahiRuleMixedScriptVisible,
      isAsahiRuleKanjiAllowedVisible: state.isAsahiRuleKanjiAllowedVisible,
      isAsahiRuleNumericFormatVisible: state.isAsahiRuleNumericFormatVisible,
      isAsahiRuleMisuseVisible: state.isAsahiRuleMisuseVisible,
      isAsahiRuleIdiomaticExpressionVisible: state.isAsahiRuleIdiomaticExpressionVisible,
      isAsahiRuleCommonNameVisible: state.isAsahiRuleCommonNameVisible,
      isAsahiRuleKatakanaCautionVisible: state.isAsahiRuleKatakanaCautionVisible,
      isAsahiRuleKanaCautionVisible: state.isAsahiRuleKanaCautionVisible,
      isAsahiRuleCharacterTypeCautionVisible: state.isAsahiRuleCharacterTypeCautionVisible,
      isAsahiRuleHomonymVisible: state.isAsahiRuleHomonymVisible,
      isAsahiRuleHonorificsCautionVisible: state.isAsahiRuleHonorificsCautionVisible,
      isAsahiRuleKanaReadingVisible: state.isAsahiRuleKanaReadingVisible,
      isAsahiRuleZenkakuVisible: state.isAsahiRuleZenkakuVisible,
      isAsahiRuleInputConversionErrorVisible: state.isAsahiRuleInputConversionErrorVisible,
      isAsahiRuleRedundantExpressionVisible: state.isAsahiRuleRedundantExpressionVisible,
      isAsahiRuleOmittedCharacterVisible: state.isAsahiRuleOmittedCharacterVisible,
      isAsahiRulePlaceNameCautionVisible: state.isAsahiRulePlaceNameCautionVisible,
      isAsahiRuleProperNounCautionVisible: state.isAsahiRuleProperNounCautionVisible,
      isAsahiRuleTechnicalTermVisible: state.isAsahiRuleTechnicalTermVisible,

      isTextlintErrorVisible: state.isTextlintErrorVisible,
      isTextlintNoHankakuKanaVisible: state.isTextlintNoHankakuKanaVisible,
      isTextlintNoDoubledJoshiVisible: state.isTextlintNoDoubledJoshiVisible,
      isTextlintNoDoubleNegativeVisible: state.isTextlintNoDoubleNegativeVisible,
      isTextlintNoMixDearuDesumasuVisible: state.isTextlintNoMixDearuDesumasuVisible,
      isTextlintSentenceLengthVisible: state.isTextlintSentenceLengthVisible,
      isTextlintNoUnmatchedPairVisible: state.isTextlintNoUnmatchedPairVisible,
      isTextlintNoDoubledGaVisible: state.isTextlintNoDoubledGaVisible,
      isTextlintNoNfdVisible: state.isTextlintNoNfdVisible,
      isTextlintNoInsertDroppingSaVisible: state.isTextlintNoInsertDroppingSaVisible,
      isTextlintNoDroppingRaVisible: state.isTextlintNoDroppingRaVisible,
      isTextlintPreferTariTariVisible: state.isTextlintPreferTariTariVisible,
      isTextlintNoRedundantExpressionVisible: state.isTextlintNoRedundantExpressionVisible,
      isTextlintNoSuccessiveWordVisible: state.isTextlintNoSuccessiveWordVisible,
      isTextlintNoSynonymsVisible: state.isTextlintNoSynonymsVisible,
      isTextlintMaxTenVisible: state.isTextlintMaxTenVisible,
      isTextlintNoAbusageVisible: state.isTextlintNoAbusageVisible,
      isTextlintKyoikuKanjiVisible: state.isTextlintKyoikuKanjiVisible,
      isTextlintUnnaturalAlphabetVisible: state.isTextlintUnnaturalAlphabetVisible,
      isTextlintUseSiUnitsVisible: state.isTextlintUseSiUnitsVisible,
      isTextlintHiraganaKeishikimeishiVisible: state.isTextlintHiraganaKeishikimeishiVisible,
      isTextlintHiraganaFukushiVisible: state.isTextlintHiraganaFukushiVisible,
      isTextlintHiraganaHojodoushiVisible: state.isTextlintHiraganaHojodoushiVisible,
      isTextlintDateWeekdayMismatchVisible: state.isTextlintDateWeekdayMismatchVisible,
      isTextlintNoMixedPeriodVisible: state.isTextlintNoMixedPeriodVisible,
      isTextlintNoZenkakuNumbers: state.isTextlintNoZenkakuNumbers,
    }))
  );

  const visibilityAsahiRuleMap: Record<AsahiRuleSubCategory, boolean> = useMemo(() => {
    return {
      [AsahiRuleSubCategory.INAPPROPRIATE_EXPRESSIONS]: isAsahiRuleInappropriateExpressionsVisible,
      [AsahiRuleSubCategory.NON_JOYO_KANJI]: isAsahiRuleNonJoyoKanjiVisible,
      [AsahiRuleSubCategory.NON_JOYO_READING]: isAsahiRuleNonJoyoReadingVisible,
      [AsahiRuleSubCategory.CHARACTER_FORM]: isAsahiRuleCharacterFormVisible,
      [AsahiRuleSubCategory.MIXED_SCRIPT]: isAsahiRuleMixedScriptVisible,
      [AsahiRuleSubCategory.KANJI_ALLOWED]: isAsahiRuleKanjiAllowedVisible,
      [AsahiRuleSubCategory.NUMERIC_FORMAT]: isAsahiRuleNumericFormatVisible,
      [AsahiRuleSubCategory.MISUSE]: isAsahiRuleMisuseVisible,
      [AsahiRuleSubCategory.IDIOMATIC_EXPRESSION]: isAsahiRuleIdiomaticExpressionVisible,
      [AsahiRuleSubCategory.COMMON_NAME]: isAsahiRuleCommonNameVisible,
      [AsahiRuleSubCategory.KATAKANA_CAUTION]: isAsahiRuleKatakanaCautionVisible,
      [AsahiRuleSubCategory.KANA_CAUTION]: isAsahiRuleKanaCautionVisible,
      [AsahiRuleSubCategory.CHARACTER_TYPE_CAUTION]: isAsahiRuleCharacterTypeCautionVisible,
      [AsahiRuleSubCategory.HOMONYM]: isAsahiRuleHomonymVisible,
      [AsahiRuleSubCategory.HONORIFICS_CAUTION]: isAsahiRuleHonorificsCautionVisible,
      [AsahiRuleSubCategory.KANA_READING]: isAsahiRuleKanaReadingVisible,
      [AsahiRuleSubCategory.ZENKAKU]: isAsahiRuleZenkakuVisible,
      [AsahiRuleSubCategory.INPUT_CONVERSION_ERROR]: isAsahiRuleInputConversionErrorVisible,
      [AsahiRuleSubCategory.REDUNDANT_EXPRESSION]: isAsahiRuleRedundantExpressionVisible,
      [AsahiRuleSubCategory.OMITTED_CHARACTER]: isAsahiRuleOmittedCharacterVisible,
      [AsahiRuleSubCategory.PLACE_NAME_CAUTION]: isAsahiRulePlaceNameCautionVisible,
      [AsahiRuleSubCategory.PROPER_NOUN_CAUTION]: isAsahiRuleProperNounCautionVisible,
      [AsahiRuleSubCategory.TECHNICAL_TERM]: isAsahiRuleTechnicalTermVisible,
      [AsahiRuleSubCategory.VERTICAL_WRITING]: false,
      [AsahiRuleSubCategory.ASAHI_RULE]: false,
    };
  }, [
    isAsahiRuleHonorificsCautionVisible,
    isAsahiRuleCharacterFormVisible,
    isAsahiRuleCharacterTypeCautionVisible,
    isAsahiRuleCommonNameVisible,
    isAsahiRuleHomonymVisible,
    isAsahiRuleIdiomaticExpressionVisible,
    isAsahiRuleInappropriateExpressionsVisible,
    isAsahiRuleInputConversionErrorVisible,
    isAsahiRuleKanaCautionVisible,
    isAsahiRuleKanaReadingVisible,
    isAsahiRuleKanjiAllowedVisible,
    isAsahiRuleKatakanaCautionVisible,
    isAsahiRuleMisuseVisible,
    isAsahiRuleMixedScriptVisible,
    isAsahiRuleNonJoyoKanjiVisible,
    isAsahiRuleNonJoyoReadingVisible,
    isAsahiRuleNumericFormatVisible,
    isAsahiRuleOmittedCharacterVisible,
    isAsahiRulePlaceNameCautionVisible,
    isAsahiRuleProperNounCautionVisible,
    isAsahiRuleRedundantExpressionVisible,
    isAsahiRuleTechnicalTermVisible,
    isAsahiRuleZenkakuVisible,
  ]);

  const visibilityTextlintMap: Record<TextlintCategory, boolean> = useMemo(() => {
    return {
      [TextlintCategory.NO_HANKAKU_KANA]: isTextlintNoHankakuKanaVisible,
      [TextlintCategory.NO_DOUBLED_JOSHI]: isTextlintNoDoubledJoshiVisible,
      [TextlintCategory.NO_DOUBLE_NEGATIVE]: isTextlintNoDoubleNegativeVisible,
      [TextlintCategory.NO_MIX_DEARU_DESUMASU]: isTextlintNoMixDearuDesumasuVisible,
      [TextlintCategory.SENTENCE_LENGTH]: isTextlintSentenceLengthVisible,
      [TextlintCategory.NO_UNMATCHED_PAIR]: isTextlintNoUnmatchedPairVisible,
      [TextlintCategory.NO_DOUBLED_GA]: isTextlintNoDoubledGaVisible,
      [TextlintCategory.NO_NFD]: isTextlintNoNfdVisible,
      [TextlintCategory.NO_INSERT_DROPPING_SA]: isTextlintNoInsertDroppingSaVisible,
      [TextlintCategory.NO_DROPPING_RA]: isTextlintNoDroppingRaVisible,
      [TextlintCategory.PREFER_TARI_TARI]: isTextlintPreferTariTariVisible,
      [TextlintCategory.NO_REDUNDANT_EXPRESSION]: isTextlintNoRedundantExpressionVisible,
      [TextlintCategory.NO_SUCCESSIVE_WORD]: isTextlintNoSuccessiveWordVisible,
      [TextlintCategory.NO_SYNONYMS]: isTextlintNoSynonymsVisible,
      [TextlintCategory.MAX_TEN]: isTextlintMaxTenVisible,
      [TextlintCategory.NO_ABUSAGE]: isTextlintNoAbusageVisible,
      [TextlintCategory.KYOIKU_KANJI]: isTextlintKyoikuKanjiVisible,
      [TextlintCategory.UNNATURAL_ALPHABET]: isTextlintUnnaturalAlphabetVisible,
      [TextlintCategory.USE_SI_UNITS]: isTextlintUseSiUnitsVisible,
      [TextlintCategory.HIRAGANA_KEISHIKIMEISHI]: isTextlintHiraganaKeishikimeishiVisible,
      [TextlintCategory.HIRAGANA_FUKUSHI]: isTextlintHiraganaFukushiVisible,
      [TextlintCategory.HIRAGANA_HOJODOUSHI]: isTextlintHiraganaHojodoushiVisible,
      [TextlintCategory.DATE_WEEKDAY_MISMATCH]: isTextlintDateWeekdayMismatchVisible,
      [TextlintCategory.NO_MIXED_PERIOD]: isTextlintNoMixedPeriodVisible,
      [TextlintCategory.NO_ZENKAKU_NUMBERS]: isTextlintNoZenkakuNumbers,
    };
  }, [
    isTextlintNoHankakuKanaVisible,
    isTextlintNoDoubledJoshiVisible,
    isTextlintNoDoubleNegativeVisible,
    isTextlintNoMixDearuDesumasuVisible,
    isTextlintSentenceLengthVisible,
    isTextlintNoUnmatchedPairVisible,
    isTextlintNoDoubledGaVisible,
    isTextlintNoNfdVisible,
    isTextlintNoInsertDroppingSaVisible,
    isTextlintNoDroppingRaVisible,
    isTextlintPreferTariTariVisible,
    isTextlintNoRedundantExpressionVisible,
    isTextlintNoSuccessiveWordVisible,
    isTextlintNoSynonymsVisible,
    isTextlintMaxTenVisible,
    isTextlintNoAbusageVisible,
    isTextlintKyoikuKanjiVisible,
    isTextlintUnnaturalAlphabetVisible,
    isTextlintUseSiUnitsVisible,
    isTextlintHiraganaKeishikimeishiVisible,
    isTextlintHiraganaFukushiVisible,
    isTextlintHiraganaHojodoushiVisible,
    isTextlintDateWeekdayMismatchVisible,
    isTextlintNoMixedPeriodVisible,
    isTextlintNoZenkakuNumbers,
  ]);

  // エラーパネルフィルター及び校正設定に応じて、表示するエラーをフィルタリングする。
  // エラーパネルフィルタリング：1段階目
  // 校正設定フィルタリング：2段階目, 朝日ルール/textlintはカテゴリ別の可視性がある。
  const visibleErrors = useMemo(
    () =>
      errors
        .filter(
          (error) =>
            (isTyeErrorVisible && error.type === "tye") ||
            (isCustomRuleErrorVisible && error.type === "customRule") ||
            (isAsahiRuleErrorVisible && error.type === "asahiRule") ||
            (isTextlintErrorVisible && error.type === "textlint") ||
            (isFlamingRiskErrorVisible && error.type === "flamingRisk")
        )
        .filter((error) => {
          if (error.type === "asahiRule") {
            return error.categories.some(
              (category) =>
                visibilityAsahiRuleMap[category] || // カテゴリが定義されている場合はそのカテゴリの可視性を適用
                !Object.prototype.hasOwnProperty.call(visibilityAsahiRuleMap, category) // カテゴリが未定義の場合は表示する
            );
          }

          if (error.type === "textlint") {
            return (
              visibilityTextlintMap[error.category] || // カテゴリが定義されている場合はそのカテゴリの可視性を適用
              !Object.prototype.hasOwnProperty.call(visibilityTextlintMap, error.category) // カテゴリが未定義の場合は表示する
            );
          }

          return true;
        }),
    [
      errors,
      isAsahiRuleErrorVisible,
      isCustomRuleErrorVisible,
      isTextlintErrorVisible,
      isFlamingRiskErrorVisible,
      isTyeErrorVisible,
      visibilityAsahiRuleMap,
      visibilityTextlintMap,
    ]
  );

  // エラーパネルフィルターの状態には依らず、校正設定に応じて表示するエラーをフィルタリングする。
  // 校正設定フィルタリング：朝日ルール/textlintはカテゴリ別の可視性の判定あり。
  const detectedErrors = useMemo(
    () =>
      errors.filter((error) => {
        if (error.type === "asahiRule") {
          return error.categories.some(
            (category) =>
              visibilityAsahiRuleMap[category] || // カテゴリが定義されている場合はそのカテゴリの可視性を適用
              !Object.prototype.hasOwnProperty.call(visibilityAsahiRuleMap, category) // カテゴリが未定義の場合は表示する
          );
        }

        if (error.type === "textlint") {
          return (
            visibilityTextlintMap[error.category] || // カテゴリが定義されている場合はそのカテゴリの可視性を適用
            !Object.prototype.hasOwnProperty.call(visibilityTextlintMap, error.category) // カテゴリが未定義の場合は表示する
          );
        }

        return true;
      }),
    [errors, visibilityAsahiRuleMap, visibilityTextlintMap]
  );

  return {
    detectedErrors,
    visibleErrors,
  };
}
