import React from "react";
import { useLogin } from "@src/hooks/use-login";
import { useAlertStore } from "@src/store/alert-store";
import { AlertModal } from "@src/taskpane/components/modal/alert-modal";
import { ServiceType } from "@src/types/service-type";

export function Login() {
  const isShowingAlert = useAlertStore((state) => state.isShowingAlert);
  const { login } = useLogin();

  return (
    <div className="text-center space-y-4 p-2">
      <div className="flex justify-center p-3">
        <img src="/assets/Typoless_PNG_1.png" alt="Typoless/タイポレス/たいぽれす" width={210} height={56} />
      </div>
      <div>
        <button
          className="relative w-64 items-center rounded bg-primary-500 py-5 text-[16px] text-white font-bold hover:opacity-70"
          onClick={() => login({ serviceType: ServiceType.BTOC })}
        >
          プレミアムの方はこちら
        </button>
      </div>
      <div>
        <button
          className="relative w-64 items-center rounded border-2 border-primary-500 bg-white py-5 text-[16px] text-primary-500 font-bold hover:opacity-50"
          onClick={() => login({ serviceType: ServiceType.BTOB })}
        >
          エンタープライズの方はこちら
        </button>
      </div>
      <button
        className="pb-14 pt-4 text-center text-sm text-primary-500 underline hover:opacity-50"
        onClick={() => {
          window.open(`${process.env.TYPOLESS_WEB_URL}/signup`, "_blank");
        }}
      >
        もしくは新規登録する
      </button>

      <AlertModal isOpen={isShowingAlert} />
    </div>
  );
}
