import { PROOFREADING_PRECISION_LEVEL } from "@src/constants";
import { Labels } from "@src/types/editor-response";
import type { Prediction } from "@src/types/editor-response";

export function buildDisplayText(
  predictions: Prediction[],
  index: number,
  label: Labels,
  candidate: string,
  precision: number
) {
  const result = [];
  const prefix = "…";
  // 表示する区切り文字
  const delimiter = ["\r", ",", "、", "。", ".", "．"];
  // 表示しない区切り文字(表示すると文字化けするため)
  const hiddenDelimiter = ["\u000b"];
  let isError = label !== Labels.ADD_LABEL && label !== Labels.FIRST_ADD_LABEL;

  if (label === Labels.ADD_LABEL) {
    result.push({
      char: "　".repeat(candidate.length),
      isError: true,
    });
  }

  // 前
  let isFirstAdd = label === Labels.FIRST_ADD_LABEL;
  for (let i = index; i >= 0; i--) {
    if (!predictions[i] || hiddenDelimiter.includes(predictions[i].char)) {
      break;
    }
    if (
      !predictions[i].label.includes(label) ||
      predictions[i].score < PROOFREADING_PRECISION_LEVEL[predictions[i].label][precision]
    ) {
      isError = false;
    }
    result.unshift({
      char: predictions[i].char,
      isError: isError,
    });
    if (isFirstAdd) {
      result.unshift({
        char: "　".repeat(candidate.length),
        isError: true,
      });
      isFirstAdd = false;
    }
    if (result.length > 5) {
      if (predictions[i - 1]) {
        result.unshift({
          char: prefix,
          isError: false,
        });
      }
      break;
    }
    if (predictions[i - 1] && delimiter.includes(predictions[i - 1].char)) {
      break;
    }
  }

  // 後ろ
  for (let i = index + 1; i < predictions.length; i++) {
    if (
      !predictions[i] ||
      delimiter.includes(predictions[i - 1].char) ||
      hiddenDelimiter.includes(predictions[i].char)
    ) {
      break;
    }
    result.push({
      char: predictions[i].char,
      isError: false,
    });
    if (result.length > 10) {
      if (predictions[i + 1] && predictions[i + 1].char !== "\n") {
        result.push({
          char: prefix,
          isError: false,
        });
      }
      break;
    }
  }
  return result;
}
