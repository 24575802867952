import React from "react";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { useVisibleErrors } from "@src/store/use-visible-errors";
import { CustomButton } from "@src/taskpane/components/button/custom-button";
import { focusNextErrorPanel } from "@src/taskpane/error-panels/error-panel/focus-next-error-panel";
import { Labels } from "@src/types/editor-response";
import type { TypolessError } from "@src/types/editor-response";
import { ErrorPanelType } from "@src/types/error-panel-type";
import { buildInputTextStates } from "@src/utils/build-Input-text-states";
import { revise } from "./revise";

export function ReviseButton({ error, panelType }: { error: TypolessError; panelType: ErrorPanelType }) {
  const reviseError = useProofreadingStore((state) => state.reviseError);
  const setSelectedError = useProofreadingStore((state) => state.setSelectedError);
  const inputTextStates = useProofreadingStore((state) => state.inputTextStates);

  const { visibleErrors } = useVisibleErrors();

  const getReviseTextLabel = (error: TypolessError) => {
    if (error.type === "tye") {
      switch (error.label) {
        case Labels.DELETE_LABEL:
          return "トル";
        case Labels.REPLACE_LABEL:
          return `「${error.candidate}」に置換`;
        case Labels.FIRST_ADD_LABEL:
        case Labels.ADD_LABEL:
          return `「${error.candidate}」を挿入`;
        default:
          console.error(`想定外のラベルです: ${error.label}`);
          break;
      }
    }

    if (error.candidate) {
      return `「${error.candidate}」に置換`;
    }
    return "";
  };

  return (
    <CustomButton
      type="dark"
      text={getReviseTextLabel(error)}
      onClick={async () => {
        await revise({
          targetError: error,
          reviseError,
          inputTextStates,
        });
        const newInputTextStates = await buildInputTextStates(inputTextStates);
        await focusNextErrorPanel({
          visibleErrors,
          id: error.id,
          setSelectedError,
          inputTextStates: newInputTextStates,
        });
      }}
      disabled={panelType !== ErrorPanelType.UNFIXED}
    />
  );
}
