import React, { Fragment } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { useAlertStore } from "@src/store/alert-store";
import { useUserStore } from "@src/store/user-store";
import { logout } from "@src/utils/logout";
import clsx from "clsx";

export function AlertModal({ isOpen }: Readonly<{ isOpen: boolean }>) {
  const alertTitle = useAlertStore((state) => state.alertTitle);
  const alertBody = useAlertStore((state) => state.alertBody);
  const hideAlert = useAlertStore((state) => state.hideAlert);
  const isLogout = useAlertStore((state) => state.isLogout);

  const serviceType = useUserStore((state) => state.serviceType);
  const clearUser = useUserStore((state) => state.clearUser);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 font-gothic"
        onClose={() => {
          hideAlert();
          if (isLogout) {
            logout({ serviceType, clearUser });
          }
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-80 transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-center text-center">
                  <img alt="アラート" src="/assets/alert.svg" width="32" height="32" />
                </div>
                <DialogTitle className="py-1.5 text-center text-xl">{alertTitle}</DialogTitle>
                <div className="py-1 text-center  text-sm">{alertBody}</div>
                <div className="mt-4">
                  <button
                    onClick={() => {
                      hideAlert();
                      if (isLogout) {
                        logout({ serviceType, clearUser });
                      }
                    }}
                    className={clsx(
                      "w-full text-white rounded border px-4 py-2 text-center text-sm font-bold bg-neutral-900 border-neutral-900",
                      "hover:bg-neutral-200 hover:border-neutral-200 hover:text-neutral-900"
                    )}
                  >
                    閉じる
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
