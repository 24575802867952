import React from "react";
import { Labels } from "@src/types/editor-response";
import type { TypolessError } from "@src/types/editor-response";
import { clsx } from "clsx";

export function TyeErrorText({ error }: { error: TypolessError }) {
  return (
    <div>
      {error.type === "tye" &&
        [...error.texts].map((text, key) => {
          if (text.isError) {
            return (
              <span
                key={key}
                className={clsx(
                  "bg-primary-100 text-red-500 underline",
                  error.label === Labels.DELETE_LABEL && "line-through"
                )}
              >
                {text.char}
              </span>
            );
          }
          return <span key={key}>{text.char}</span>;
        })}
    </div>
  );
}
