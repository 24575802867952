import React from "react";
import { useEditorSettingStore } from "@src/store/editor-setting-store";
import type { ProofreadingPrecision } from "@src/store/editor-setting-store";
import { BsCircleFill } from "react-icons/bs";

export function ProofreadingPrecision() {
  const precision = useEditorSettingStore((state) => state.precision);
  const setPrecision = useEditorSettingStore((state) => state.setPrecision);

  return (
    <div className="relative z-10 w-28 text-center flex justify-center">
      <input
        title="proofreading-precision"
        type="range"
        min="0"
        max="2"
        step="1"
        value={precision}
        className="h-[2px] w-full mx-2 cursor-pointer appearance-none rounded-lg accent-neutral-700 bg-neutral-100"
        onChange={(e) => {
          setPrecision(Number(e.target.value) as ProofreadingPrecision);
        }}
      />
      <BsCircleFill color="#EDEFF2" size="12px" className="absolute left-[8px] top-[-5px] -z-10" />
      <span className="absolute left-[-2px] top-2.5 text-[10px] text-neutral-500">積極的</span>
      <BsCircleFill color="#EDEFF2" size="12px" className="absolute left-[50px] top-[-5px] -z-10" />
      <BsCircleFill color="#EDEFF2" size="12px" className="absolute left-[92px] top-[-5px] -z-10" />
      <span className="absolute left-[82px] top-2.5 text-[10px] text-neutral-500">消極的</span>
    </div>
  );
}
