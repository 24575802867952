import { ServiceType } from "@src/types/service-type";

export async function logout({ serviceType, clearUser }: { serviceType: ServiceType; clearUser: () => void }) {
  const encodedCredentials = btoa(
    `${process.env.TYPOLESS_BASIC_AUTHORIZATION_USER_NAME}:${process.env.TYPOLESS_BASIC_AUTHORIZATION_PASSWORD}`
  );
  await fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/auth/sign-out`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
    },
    credentials: "include",
  });

  const [issuerUrl, clientId] =
    serviceType === ServiceType.BTOC
      ? [process.env.AUTH0_OFFICE_ADD_IN_BTOC_ISSUER, process.env.AUTH0_OFFICE_ADD_IN_BTOC_CLIENT_ID]
      : [process.env.AUTH0_OFFICE_ADD_IN_BTOB_ISSUER, process.env.AUTH0_OFFICE_ADD_IN_BTOB_CLIENT_ID];
  const redirectUrl = encodeURIComponent(`https://${window.location.host}/taskpane.html`);

  // localstorageを削除する
  clearUser();
  localStorage.removeItem("isAuthenticated");

  location.href = `${issuerUrl}/v2/logout?client_id=${clientId}&returnTo=${redirectUrl}`;
}
