import type { InputTextStates } from "@src/store/proofreading-store";
import type { TypolessError } from "@src/types/editor-response";
import { getOrderNo } from "@src/utils/get-order-no";
import { getPreviousText } from "@src/utils/get-previous-text";

export const focusErrorPanel = async ({
  targetError,
  setSelectedError,
  behavior,
  inputTextStates,
}: {
  targetError: TypolessError;
  setSelectedError: (error: TypolessError) => void;
  behavior: "auto" | "smooth";
  inputTextStates: InputTextStates[];
}): Promise<{ ok: boolean }> => {
  return await Word.run(async (context) => {
    const results = context.document.body.search(targetError.errorText, {
      matchCase: true,
    });
    results.load("items");
    await context.sync();

    const orderNo = getOrderNo({
      text: targetError.errorText,
      previousText: getPreviousText(inputTextStates, targetError.id),
    });
    if (!results.items[orderNo]) {
      return { ok: false };
    }

    results.items[orderNo].getRange().select();
    setSelectedError(targetError);

    const errorPanel = document.getElementById(`error-panel-id-${targetError.id}`);
    errorPanel.scrollIntoView({ behavior });

    return { ok: true };
  }).catch((error) => {
    console.error(error);
    return { ok: false };
  });
};
