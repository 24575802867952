import type {
  AsahiRuleError,
  CustomRuleError,
  TextlintError,
  TyeError,
  TypolessError,
} from "@src/types/editor-response";

export function existsIgnoredErrors(
  error:
    | Pick<TyeError, "type" | "label" | "candidate" | "chunk">
    | Pick<CustomRuleError, "type" | "candidate" | "errorText">
    | Pick<AsahiRuleError, "type" | "candidate" | "errorText">
    | Pick<TextlintError, "type" | "candidate" | "category">,
  ignoredErrors: TypolessError[]
) {
  switch (error.type) {
    case "tye": {
      const ignoredTyeErrors = ignoredErrors.filter((error): error is TyeError => error.type === "tye");
      return ignoredTyeErrors.some(({ label, candidate, chunk }) => {
        return label === error.label && candidate === error.candidate && chunk === error.chunk;
      });
    }
    case "asahiRule": {
      const ignoredAsahiRuleErrors = ignoredErrors.filter(
        (error): error is AsahiRuleError => error.type === "asahiRule"
      );
      return ignoredAsahiRuleErrors.some(({ candidate, errorText }) => {
        return candidate === error.candidate && errorText === error.errorText;
      });
    }
    case "customRule": {
      const ignoredCustomRuleErrors = ignoredErrors.filter(
        (error): error is CustomRuleError => error.type === "customRule"
      );
      return ignoredCustomRuleErrors.some(({ candidate, errorText }) => {
        return candidate === error.candidate && errorText === error.errorText;
      });
    }
    case "textlint": {
      const ignoredTextlintErrors = ignoredErrors.filter((error): error is TextlintError => error.type === "textlint");
      return ignoredTextlintErrors.some(({ candidate, category }) => {
        return candidate === error.candidate && category === error.category;
      });
    }
  }
}
