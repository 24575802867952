import type { InputTextStates } from "@src/store/proofreading-store";
import { Labels } from "@src/types/editor-response";
import type { TypolessError } from "@src/types/editor-response";
import { getOrderNo } from "@src/utils/get-order-no";
import { getPreviousText } from "@src/utils/get-previous-text";

export async function revise({
  targetError,
  reviseError,
  inputTextStates,
}: {
  targetError: TypolessError;
  reviseError: (targetError: TypolessError) => void;
  inputTextStates: InputTextStates[];
}) {
  await Word.run(async (context) => {
    const results = context.document.body.search(targetError.errorText, {
      matchCase: true,
    });
    results.load("items");
    await context.sync();

    const previousText = getPreviousText(inputTextStates, targetError.id);
    const orderNo = getOrderNo({ text: targetError.errorText, previousText });
    if (targetError.type === "tye") {
      if (targetError.label === Labels.DELETE_LABEL) {
        results.items[orderNo].insertText("", "Replace");
      } else if (targetError.label === Labels.FIRST_ADD_LABEL) {
        results.items[orderNo].insertText(targetError.candidate, "Start");
      } else if (targetError.label === Labels.ADD_LABEL) {
        results.items[orderNo].insertText(targetError.candidate, "End");
      } else {
        results.items[orderNo].insertText(targetError.candidate, "Replace");
      }
    } else {
      results.items[orderNo].insertText(targetError.candidate, "Replace");
    }
    await context.sync();

    reviseError(targetError);
  }).catch((error) => {
    console.error(error);
  });
}
