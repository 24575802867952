import React from "react";
import type { ReactNode } from "react";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { CustomButton } from "@src/taskpane/components/button/custom-button";

export function ConfirmModal({
  isOpen,
  onConfirm,
  onClose,
  confirmMessage,
  executeText,
  cancelText,
}: Readonly<{
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  confirmMessage: ReactNode;
  executeText: string;
  cancelText: string;
}>) {
  return (
    <Transition appear show={isOpen}>
      <Dialog className="relative z-50 font-gothic" onClose={onClose}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="transform overflow-hidden rounded-md bg-white p-6 shadow-xl transition-all">
                <div className="mt-2">
                  <div className="text-left text-sm">{confirmMessage}</div>
                </div>
                {executeText && (
                  <div className="mt-3 grid">
                    <CustomButton
                      type="dark"
                      text={executeText}
                      onClick={() => {
                        if (onConfirm) {
                          onConfirm();
                        }
                      }}
                    />
                  </div>
                )}
                {cancelText && (
                  <div className="mt-3 grid">
                    <CustomButton type="default" text={cancelText} onClick={onClose} />
                  </div>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
