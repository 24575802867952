import React, { Fragment, useState } from "react";
import { Dialog, DialogPanel, Transition } from "@headlessui/react";
import { useAlertStore } from "@src/store/alert-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { useUserStore } from "@src/store/user-store";
import { CustomButton } from "@src/taskpane/components/button/custom-button";
import type { AsahiRuleError, IgnoredTypolessError } from "@src/types/editor-response";
import { isWordAvailableBtoCUser } from "@src/utils/is-btoc-premium";

export function RevertErrorButton({
  error,
}: Readonly<{
  error: IgnoredTypolessError;
}>) {
  const revertErrorTemporary = useProofreadingStore((state) => state.revertErrorTemporary);
  if (error.type === "asahiRule" && error.isPermanently) {
    return <AsahiRuleRevertErrorButton error={error} />;
  }

  return (
    <button
      onClick={() => {
        revertErrorTemporary(error);
      }}
    >
      <img src="/assets/revert.svg" width="24" height="24" alt="元に戻す" />
    </button>
  );
}

function AsahiRuleRevertErrorButton({ error }: { error: AsahiRuleError }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const showAlert = useAlertStore((state) => state.showAlert);
  const plan = useUserStore((state) => state.plan);

  const hiddenDialog = useProofreadingStore((state) => state.hiddenDialogRevertPermanentlyIgnoreError);
  const setHiddenDialog = useProofreadingStore((state) => state.setHiddenDialogRevertPermanentlyIgnoreError);
  const revertErrorPermanently = useProofreadingStore((state) => state.revertErrorPermanently);

  const revertIgnoredAsahiRule = async (error: AsahiRuleError) => {
    const encodedCredentials = btoa(
      `${process.env.TYPOLESS_BASIC_AUTHORIZATION_USER_NAME}:${process.env.TYPOLESS_BASIC_AUTHORIZATION_PASSWORD}`
    );
    const res = await fetch(
      `${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/ignored-asahi-rules/revert-ignored-asahi-rule`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
        },
        body: JSON.stringify({ error }),
        credentials: "include",
      }
    );
    if (!res.ok) {
      showAlert({
        alertTitle: "アプリケーションエラー",
        alertBody: "エラーが発生しました",
      });
      return;
    }
    revertErrorPermanently(error);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          if (isWordAvailableBtoCUser({ plan }) || hiddenDialog) {
            revertIgnoredAsahiRule(error);
          } else {
            openModal();
          }
        }}
      >
        <img src="/assets/revert.svg" width="24" height="24" alt="元に戻す" />
      </button>

      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog
          className="relative z-50"
          onClose={() => {
            setHiddenDialog(false);
            closeModal();
          }}
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
          <div className="fixed inset-0 flex w-screen items-center justify-center font-gothic">
            <DialogPanel className="mx-auto max-w-sm rounded-lg bg-white p-6 text-sm leading-relaxed w-72">
              <p>組織ユーザー全員に対して永続無視が解除されます。</p>
              <p>永続無視を解除してよろしいでしょうか？</p>

              <div className="my-4 flex items-center justify-center gap-2">
                <input
                  type="checkbox"
                  id="never-show-message-again"
                  className="scale-125"
                  checked={hiddenDialog}
                  onChange={(e) => {
                    setHiddenDialog(e.target.checked);
                  }}
                />
                <label htmlFor="never-show-message-again">今後このメッセージを表示しない</label>
              </div>

              <div className="flex flex-col gap-2">
                <CustomButton
                  type="dark"
                  text="永続無視を解除する"
                  onClick={() => {
                    revertIgnoredAsahiRule(error);
                    closeModal();
                  }}
                />
                <CustomButton
                  type="default"
                  text="キャンセル"
                  onClick={() => {
                    setHiddenDialog(false);
                    closeModal();
                  }}
                />
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
