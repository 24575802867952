import type React from "react";
import { create } from "zustand";

export const DialogType = {
  Success: "Success",
  Alert: "Alert",
} as const;
export type DialogType = (typeof DialogType)[keyof typeof DialogType];

type Alert = {
  alertTitle: string;
  alertBody: string | React.JSX.Element;
  isLogout?: boolean;
  dialogType?: DialogType;
};

type AlertStore = {
  isShowingAlert: boolean;
  alertTitle: string;
  alertBody: string | React.JSX.Element;
  isLogout: boolean;
  dialogType: DialogType;

  showAlert: (alert: Alert) => void;
  hideAlert: () => void;
};

const initialAlertState = {
  isShowingAlert: false,
  alertTitle: "",
  alertBody: "",
  isLogout: false,
  dialogType: DialogType.Alert,
};

export const useAlertStore = create<AlertStore>()((set) => ({
  ...initialAlertState,
  showAlert: (alert: Alert) => set(() => ({ ...alert, isShowingAlert: true })),
  hideAlert: () => set(() => ({ ...initialAlertState })),
}));
