import React, { useState } from "react";
import { MdOutlineSettings } from "react-icons/md";
import { TextlintCategorySettingsModal } from "./textlint-category-settings-modal";

export function TextlintCategorySettingsButton() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <button
        id="textlint-category-settings"
        title="良文サポートカテゴリー設定"
        type="button"
        className="flex text-neutral-900 hover:text-neutral-500 disabled:text-neutral-500"
      >
        <MdOutlineSettings className="size-4" onClick={openModal} />
      </button>
      <TextlintCategorySettingsModal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
}
