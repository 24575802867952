export const TextlintCategory = {
  // 半角カナ
  NO_HANKAKU_KANA: "NO_HANKAKU_KANA",
  // 助詞の連続
  NO_DOUBLED_JOSHI: "NO_DOUBLED_JOSHI",
  // 二重否定
  NO_DOUBLE_NEGATIVE: "NO_DOUBLE_NEGATIVE",
  // ですます・である
  NO_MIX_DEARU_DESUMASU: "NO_MIX_DEARU_DESUMASU",
  // 文の長さ
  SENTENCE_LENGTH: "SENTENCE_LENGTH",
  // かっこの対応
  NO_UNMATCHED_PAIR: "NO_UNMATCHED_PAIR",
  // 「が」の連続
  NO_DOUBLED_GA: "NO_DOUBLED_GA",
  // NFD（゛゜）
  NO_NFD: "NO_NFD",
  // サ抜き言葉
  NO_INSERT_DROPPING_SA: "NO_INSERT_DROPPING_SA",
  // ラ抜き言葉
  NO_DROPPING_RA: "NO_DROPPING_RA",
  // 〜たり〜たり
  PREFER_TARI_TARI: "PREFER_TARI_TARI",
  // 冗長な表現
  NO_REDUNDANT_EXPRESSION: "NO_REDUNDANT_EXPRESSION",
  // 同一単語の連続
  NO_SUCCESSIVE_WORD: "NO_SUCCESSIVE_WORD",
  // 同義語表記揺れ
  NO_SYNONYMS: "NO_SYNONYMS",
  // 読点の数
  MAX_TEN: "MAX_TEN",
  // 日本語の誤用
  NO_ABUSAGE: "NO_ABUSAGE",
  // 教育漢字
  KYOIKU_KANJI: "KYOIKU_KANJI",
  // 不自然な英字
  UNNATURAL_ALPHABET: "UNNATURAL_ALPHABET",
  // SI単位
  USE_SI_UNITS: "USE_SI_UNITS",
  // 形式名詞のひらがな表記
  HIRAGANA_KEISHIKIMEISHI: "HIRAGANA_KEISHIKIMEISHI",
  // 副詞のひらがな表記
  HIRAGANA_FUKUSHI: "HIRAGANA_FUKUSHI",
  // 補助動詞のひらがな表記
  HIRAGANA_HOJODOUSHI: "HIRAGANA_HOJODOUSHI",
  // 年月日と曜日の不一致
  DATE_WEEKDAY_MISMATCH: "DATE_WEEKDAY_MISMATCH",
  // 句読点
  NO_MIXED_PERIOD: "NO_MIXED_PERIOD",
  // 算用数字の全角禁止
  NO_ZENKAKU_NUMBERS: "NO_ZENKAKU_NUMBERS",
} as const;

export const TextlintCategoryJa = {
  NO_HANKAKU_KANA: "半角カナ",
  NO_DOUBLED_JOSHI: "助詞の連続",
  NO_DOUBLE_NEGATIVE: "二重否定",
  NO_MIX_DEARU_DESUMASU: "ですます・である",
  SENTENCE_LENGTH: "文の長さ",
  NO_UNMATCHED_PAIR: "かっこの対応",
  NO_DOUBLED_GA: "「が」の連続",
  NO_NFD: "NFD（゛゜）",
  NO_INSERT_DROPPING_SA: "サ抜き言葉",
  NO_DROPPING_RA: "ラ抜き言葉",
  PREFER_TARI_TARI: "〜たり〜たり",
  NO_REDUNDANT_EXPRESSION: "冗長な表現",
  NO_SUCCESSIVE_WORD: "同一単語の連続",
  NO_SYNONYMS: "同義語表記揺れ",
  MAX_TEN: "読点の数",
  NO_ABUSAGE: "日本語の誤用",
  KYOIKU_KANJI: "教育漢字",
  UNNATURAL_ALPHABET: "不自然な英字",
  USE_SI_UNITS: "SI単位",
  HIRAGANA_KEISHIKIMEISHI: "形式名詞のひらがな表記",
  HIRAGANA_FUKUSHI: "副詞のひらがな表記",
  HIRAGANA_HOJODOUSHI: "補助動詞のひらがな表記",
  DATE_WEEKDAY_MISMATCH: "年月日と曜日の不一致",
  NO_MIXED_PERIOD: "句読点",
  NO_ZENKAKU_NUMBERS: "算用数字の全角禁止",
} as const;

export type TextlintCategory = (typeof TextlintCategory)[keyof typeof TextlintCategory];

export const ignoredTextlintCategories: TextlintCategory[] = [
  // TyEと被りやすいので除外
  TextlintCategory.NO_INSERT_DROPPING_SA,
  TextlintCategory.NO_DROPPING_RA,

  // TyEと被りやすい/置換したいが候補がないので除外
  TextlintCategory.UNNATURAL_ALPHABET,

  // Sudachi 同義語辞書を利用しているが、細かい設定が必要になるため除外
  TextlintCategory.NO_SYNONYMS,

  // 朝日で決めたものではないので除外
  TextlintCategory.NO_ABUSAGE,

  // 条件が複雑なので除外
  TextlintCategory.USE_SI_UNITS,

  // ほかのtextlintの指摘と被って、そちらが表示されなくなるので除外
  TextlintCategory.KYOIKU_KANJI,
];

export function getTextlintCategoryJa(category: string): string {
  const textlintCategoryKey = Object.keys(TextlintCategory).find(
    (key) => TextlintCategory[key as keyof typeof TextlintCategory] === category
  );

  if (textlintCategoryKey) {
    return TextlintCategoryJa[textlintCategoryKey as keyof typeof TextlintCategoryJa];
  }

  return category;
}
