import React from "react";
import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { useUserStore } from "@src/store/user-store";
import type { Dictionary } from "@src/types/dictionary";
import { isWordAvailableBtoCUser } from "@src/utils/is-btoc-premium";
import { clsx } from "clsx";
import { MdOutlineSpellcheck } from "react-icons/md";
import { RiExternalLinkLine } from "react-icons/ri";

export function CustomRuleSettingsButton({
  selectedDictId,
  dictionaries,
  setSelectedDictId,
}: Readonly<{
  selectedDictId: string;
  dictionaries: Dictionary[];
  setSelectedDictId: (dictId: string) => void;
}>) {
  const plan = useUserStore((state) => state.plan);
  const isWordAvailableBtoCUserFlg = isWordAvailableBtoCUser({ plan });

  return (
    <Popover className="relative font-gothic">
      <div className="flex text-neutral-900">
        <PopoverButton className="outline-none">
          <img
            src="/assets/option.svg"
            width="14"
            height="14"
            className="ml-1 outline-none text-neutral-900"
            alt="オプション"
          />
        </PopoverButton>
        <Transition
          enter="transition ease-out duration-250 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-250 trasnform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <PopoverPanel className="absolute top-8 z-10 right-0 w-44 rounded-md bg-white py-1 shadow-blur-only">
            {!isWordAvailableBtoCUserFlg &&
              dictionaries.map((dictionary) => {
                return (
                  <button
                    className="flex w-full items-center gap-1 px-3 py-2 text-sm hover:bg-neutral-100"
                    key={dictionary.id}
                    onClick={() => {
                      setSelectedDictId(dictionary.id);
                    }}
                    disabled={dictionary.id === selectedDictId}
                  >
                    {dictionary.id === selectedDictId ? (
                      <img src="/assets/check.svg" width="16" height="16" alt="" />
                    ) : (
                      <img src="/assets/dictionary.svg" width="16" height="16" alt="" />
                    )}
                    <span
                      className={clsx(
                        "break-all text-left",
                        dictionary.id === selectedDictId && "font-bold text-complementary-400"
                      )}
                    >
                      {dictionary.name}
                    </span>
                  </button>
                );
              })}
            <a
              href={
                isWordAvailableBtoCUserFlg
                  ? `${process.env.TYPOLESS_WEB_URL}/rules`
                  : `${process.env.TYPOLESS_WEB_URL}/dictionaries`
              }
              target="_blank"
              rel="noopener"
            >
              <button
                className={clsx(
                  "flex w-full items-center gap-1 px-3 py-2 text-sm hover:bg-neutral-100",
                  !isWordAvailableBtoCUserFlg && dictionaries.length > 0 && "border-t border-[#D9D9D9]"
                )}
              >
                <MdOutlineSpellcheck className="text-[#8992A2]" />
                <span>カスタム辞書編集</span>
                <RiExternalLinkLine className="text-[#8992A2]" />
              </button>
            </a>
          </PopoverPanel>
        </Transition>
      </div>
    </Popover>
  );
}
