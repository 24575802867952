import { useCallback } from "react";
import { useAlertStore } from "@src/store/alert-store";
import { useUserStore } from "@src/store/user-store";
import type { Dictionary } from "@src/types/dictionary";
import { ServiceType } from "@src/types/service-type";
import type { UserPlanType } from "@src/types/user-plan-type";
import { isWordAvailableBtoCUser } from "@src/utils/is-btoc-premium";

export function useLogin() {
  const showAlert = useAlertStore((state) => state.showAlert);

  const setPlan = useUserStore((state) => state.setPlan);
  const setDictionaries = useUserStore((state) => state.setDictionaries);
  const selectedDictId = useUserStore((state) => state.selectedDictId);
  const setSelectedDictId = useUserStore((state) => state.setSelectedDictId);
  const setServiceType = useUserStore((state) => state.setServiceType);

  const getState = () => {
    const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const N = 9;
    return Array.from(crypto.getRandomValues(new Uint8Array(N)))
      .map((n) => S[n % S.length])
      .join("");
  };

  const getErrorMessage = (error: string) => {
    switch (error) {
      case "Unauthorized":
        return "認証時に失敗しました。";
      case "Payment Required":
        return "サブスクリプションの契約が必要です。";
      case "Forbidden":
        return "word機能が利用できないユーザーです。";
      default:
        return "エラーが発生しました。";
    }
  };

  const getDefaultDictId = ({
    dictionaries,
    selectedDictId,
    plan,
  }: {
    dictionaries: Dictionary[];
    plan: UserPlanType;
    selectedDictId: string;
  }) => {
    const defaultDictId = dictionaries.find(({ isDefault }) => isDefault)?.id;
    if (!isWordAvailableBtoCUser({ plan })) {
      const dictId = dictionaries.find(({ id }) => id === selectedDictId)?.id;
      if (dictId) {
        return dictId;
      }
    }
    return defaultDictId;
  };

  const login = useCallback(async ({ serviceType }: { serviceType: ServiceType }) => {
    setServiceType(serviceType);
    const [issuerUrl, clientId] =
      serviceType === ServiceType.BTOC
        ? [process.env.AUTH0_OFFICE_ADD_IN_BTOC_ISSUER, process.env.AUTH0_OFFICE_ADD_IN_BTOC_CLIENT_ID]
        : [process.env.AUTH0_OFFICE_ADD_IN_BTOB_ISSUER, process.env.AUTH0_OFFICE_ADD_IN_BTOB_CLIENT_ID];

    // CSRF対策を行う
    // https://auth0.com/docs/secure/attack-protection/state-parameters#csrf-attacks
    const state = getState();
    localStorage.setItem("state", state);

    const params = {
      response_type: "code",
      client_id: clientId,
      redirect_uri: `https://${window.location.host}/redirect.html`,
      scope: "openid offline_access",
      state: state,
    };
    const urlSearchParam = new URLSearchParams(params).toString();
    const url = `${issuerUrl}/authorize/?` + urlSearchParam;

    let dialog;
    Office.context.ui.displayDialogAsync(url, { height: 80, width: 50 }, function (asyncResult) {
      dialog = asyncResult.value;
      dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
    });

    const processMessage = async (arg) => {
      try {
        const message = JSON.parse(arg.message);
        const code = message.code;
        const state = message.state;

        if (localStorage.getItem("state") !== state) {
          throw "Invalid csrf token";
        }

        const encodedCredentials = btoa(
          `${process.env.TYPOLESS_BASIC_AUTHORIZATION_USER_NAME}:${process.env.TYPOLESS_BASIC_AUTHORIZATION_PASSWORD}`
        );
        const res = await fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/auth/sign-in`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
          },
          body: JSON.stringify({
            code: code,
            serviceType: serviceType,
            redirectUri: `https://${window.location.host}`,
          }),
          credentials: "include",
        });
        const data = await res.json();
        if (data.error) {
          throw data.error;
        }

        const dictionaries = data.dictionaries;
        setDictionaries(dictionaries);

        const plan = data.plan;
        setPlan(plan);

        const dictId = getDefaultDictId({
          dictionaries,
          selectedDictId,
          plan,
        });
        setSelectedDictId(dictId);
        // ログインに成功しているか判定する
        localStorage.setItem("isAuthenticated", JSON.stringify(true));

        window.location.replace("/taskpane.html");
      } catch (e) {
        console.error(e);
        showAlert({
          alertTitle: "認証エラー",
          alertBody: getErrorMessage(e),
          isLogout: true,
        });
      } finally {
        localStorage.removeItem("state");
        dialog.close();
      }
    };
  }, []);
  return { login };
}
