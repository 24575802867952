import * as React from "react";
import { useMemo } from "react";
import { useEditorSettingStore } from "@src/store/editor-setting-store";
import { useVisibleErrors } from "@src/store/use-visible-errors";
import { FilterButton } from "@src/taskpane/components/button/filter-button";
import type { TypolessError } from "@src/types/editor-response";

export function FilterButtons() {
  const isTyeErrorVisible = useEditorSettingStore((state) => state.isTyeErrorVisible);
  const isAsahiRuleErrorVisible = useEditorSettingStore((state) => state.isAsahiRuleErrorVisible);
  const isCustomRuleErrorVisible = useEditorSettingStore((state) => state.isCustomRuleErrorVisible);
  const isTextlintErrorVisible = useEditorSettingStore((state) => state.isTextlintErrorVisible);
  const toggleIsTyeErrorVisible = useEditorSettingStore((state) => state.toggleIsTyeErrorVisible);
  const toggleIsAsahiRuleErrorVisible = useEditorSettingStore((state) => state.toggleIsAsahiRuleErrorVisible);
  const toggleIsCustomRuleErrorVisible = useEditorSettingStore((state) => state.toggleIsCustomRuleErrorVisible);
  const toggleIsTextlintErrorVisible = useEditorSettingStore((state) => state.toggleIsTextlintErrorVisible);

  const { detectedErrors } = useVisibleErrors();

  const errorCount = useMemo(() => {
    return detectedErrors.reduce(
      (counts, error) => {
        if (counts[error.type] === "-") {
          counts[error.type] = 0;
        }
        (counts[error.type] as number)++;
        return counts;
      },
      {
        tye: "-",
        asahiRule: "-",
        customRule: "-",
        textlint: "-",
      } as Record<TypolessError["type"], number | "-">
    );
  }, [detectedErrors]);

  return (
    <div className="flex space-x-0.5 px-1">
      <FilterButton
        type="tye"
        text="AI"
        enabled={isTyeErrorVisible}
        onClick={toggleIsTyeErrorVisible}
        errorCount={errorCount.tye}
      />
      <FilterButton
        type="asahi"
        text="ルール"
        enabled={isAsahiRuleErrorVisible}
        onClick={toggleIsAsahiRuleErrorVisible}
        errorCount={errorCount.asahiRule}
      />
      <FilterButton
        type="custom"
        text="カスタム"
        enabled={isCustomRuleErrorVisible}
        onClick={toggleIsCustomRuleErrorVisible}
        errorCount={errorCount.customRule}
      />
      <FilterButton
        type="textlint"
        text="良文"
        enabled={isTextlintErrorVisible}
        onClick={toggleIsTextlintErrorVisible}
        errorCount={errorCount.textlint}
      />
    </div>
  );
}
