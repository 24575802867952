import React, { useState } from "react";
import { EditorConfigModal } from "@src/taskpane/editor-config/editor-config-modal";
import { MdSettings } from "react-icons/md";

export function EditorConfigButton() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button onClick={() => setIsOpen(true)}>
        <MdSettings size={24} className="text-neutral-900 hover:text-neutral-500" />
      </button>
      <EditorConfigModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
