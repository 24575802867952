import React from "react";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { AsahiRuleCategorySettings } from "@src/taskpane/editor-config/asahi-rule-category-settings";
import { MdClose } from "react-icons/md";

export function AsahiRuleCategorySettingsModal({
  isOpen,
  closeModal,
}: Readonly<{
  isOpen: boolean;
  closeModal: () => void;
}>) {
  return (
    <Transition appear show={isOpen}>
      <Dialog className="relative z-50 font-gothic text-neutral-900" onClose={closeModal}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="transform rounded-lg bg-whiteshadow-xl w-72 bg-white">
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="table-cell h-12 w-12 rounded-bl-md bg-neutral-400 p-2.5 text-center align-middle"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <MdClose style={{ fontSize: "24px", color: "#fff" }} />
                  </button>
                </div>
                <div className="overflow-y-scroll h-96 px-5 pb-4 pt-2">
                  <AsahiRuleCategorySettings />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
