import * as React from "react";
import { useEffect, useState } from "react";
import { useProofread } from "@src/hooks/use-proofread";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { ProofreadingStatus } from "@src/types/proofreading-status";
import { clsx } from "clsx";
import { MdCheck } from "react-icons/md";

const INTERVAL_EXECUTE_PROOFREADING = 2000;

export function ProofreadingProgress() {
  const [isInitializing, setIsInitializing] = useState(true);

  const status = useProofreadingStore((state) => state.status);

  const { proofread } = useProofread();

  useEffect(() => {
    if (isInitializing) {
      setIsInitializing(false);
      proofread({ runImmediately: true, runFlamingRisk: true, isInitializing: true });
    }

    const intervalId = setInterval(() => proofread({ runImmediately: false }), INTERVAL_EXECUTE_PROOFREADING);
    return () => {
      clearInterval(intervalId);
    };
  }, [isInitializing, proofread]);

  return (
    <div
      className={clsx(
        "flex justify-center rounded-xl text-white w-28 text-xs",
        status === ProofreadingStatus.LOADING ? "bg-primary-600" : "bg-neutral-900"
      )}
    >
      {status === ProofreadingStatus.LOADING ? (
        <div className="flex items-center space-x-1" aria-label="校正中">
          <div className="h-3 w-3 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
          <p className="font-bold">校正中</p>
        </div>
      ) : (
        <div className="flex items-center space-x-1">
          <MdCheck className="h-3 w-3" />
          <p className="font-bold">校正済</p>
        </div>
      )}
    </div>
  );
}
