import { useCallback } from "react";
import { useProofreadingStore } from "@src/store/proofreading-store";
import type { EditorResponse } from "../types/editor-response";

type ProofreadingApiResponse =
  | EditorResponse
  | {
      error: string;
      message: string;
    };

export function useFetchProofreadingApi() {
  const maxTextLength = useProofreadingStore((state) => state.maxTextLength);

  const encodedCredentials = btoa(
    `${process.env.TYPOLESS_BASIC_AUTHORIZATION_USER_NAME}:${process.env.TYPOLESS_BASIC_AUTHORIZATION_PASSWORD}`
  );
  const fetchProofreadingApi = useCallback(
    async (text: string, selectedDictId: string): Promise<ProofreadingApiResponse> => {
      try {
        // idTokenが有効か判定し有効期限切れの場合はリフレッシュトークンを使用して再取得する
        const idTokenResponse = await fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/auth/refresh-token`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
          },
          credentials: "include",
        });
        if (!idTokenResponse.ok) {
          const idToken = await idTokenResponse.json();
          return {
            message: translateErrorMessage(idToken.error, maxTextLength),
            error: idToken.error,
          };
        }

        const tyeResponse = fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/proofreading/tye`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
          },
          credentials: "include",
          body: JSON.stringify({ text }),
        });
        const asahiRuleResponse = fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/proofreading/asahi-rule`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
          },
          credentials: "include",
          body: JSON.stringify({ text }),
        });
        const customRuleResponse =
          selectedDictId &&
          fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/proofreading/custom-rule`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
            },
            credentials: "include",
            body: JSON.stringify({
              text,
              dictId: selectedDictId,
            }),
          });

        const riskRuleResponse = fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/proofreading/risk-rule`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
          },
          credentials: "include",
          body: JSON.stringify({ text }),
        });

        const textlintResponse = fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/proofreading/textlint`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
          },
          credentials: "include",
          body: JSON.stringify({ text }),
        });

        const responses = {
          tye: await tyeResponse,
          asahiRule: await asahiRuleResponse,
          customRule: await customRuleResponse,
          riskRule: await riskRuleResponse,
          textlint: await textlintResponse,
        };

        if (!responses.tye.ok) {
          const tye = await responses.tye.json();
          return {
            message: translateErrorMessage(tye.error, maxTextLength),
            error: tye.error,
          };
        }

        if (responses.asahiRule && !responses.asahiRule.ok) {
          const asahiRule = await responses.asahiRule.json();
          return {
            message: translateErrorMessage(asahiRule.error, maxTextLength),
            error: asahiRule.error,
          };
        }

        if (responses.customRule && !responses.customRule.ok) {
          const customRule = await responses.customRule.json();
          return {
            message: translateErrorMessage(customRule.error, maxTextLength),
            error: customRule.error,
          };
        }

        if (responses.riskRule && !responses.riskRule.ok) {
          const riskRule = await responses.riskRule.json();
          return {
            message: translateErrorMessage(riskRule.error, maxTextLength),
            error: riskRule.error,
          };
        }

        if (responses.textlint && !responses.textlint.ok) {
          const textlint = await responses.textlint.json();
          return {
            message: translateErrorMessage(textlint.error, maxTextLength),
            error: textlint.error,
          };
        }

        return {
          message: "",
          error: "",
          tye: await responses.tye.json(),
          asahiRule: responses.asahiRule && (await responses.asahiRule.json()),
          customRule: responses.customRule && (await responses.customRule.json()),
          riskRule: responses.riskRule && (await responses.riskRule.json()),
          textlint: responses.textlint && (await responses.textlint.json()),
        };
      } catch (e) {
        console.error(e);
        return {
          message: "エラーが発生しました。開発者までお問い合せください",
          error: "",
        };
      }
    },
    [encodedCredentials, maxTextLength]
  );
  return { fetchProofreadingApi };
}

function translateErrorMessage(rawError: string, maxTextLength: number) {
  if (rawError === "Not Found Token") {
    return "ログインから一定時間が経過したため、再度ログインをお願いします";
  }
  if (rawError === "Payment Required") {
    return "有効なサブクリプションがありません";
  }
  if (rawError === "Authorization Error") {
    return "word機能が利用できないユーザーです";
  }
  if (rawError === "Max Text Length Error") {
    return `${maxTextLength.toLocaleString()}文字制限を超えたため、テキスト量を調整してください。`;
  }
  return "APIでエラーが生じたため、再度校正ボタンを押してください";
}
