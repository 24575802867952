import type { TypolessError } from "@src/types/editor-response";

export function getErrorPanelColorStyle({ error }: { error: TypolessError }) {
  switch (error.type) {
    case "tye":
      return {
        textColor: "text-primary-500",
        bgColor: "bg-primary-100",
        borderColor: "border-primary-500",
        hoverColor: "hover:bg-primary-100",
      };
    case "customRule":
      return {
        textColor: "text-secondary-500",
        bgColor: "bg-secondary-100",
        borderColor: "border-secondary-500",
        hoverColor: "hover:bg-secondary-100",
      };
    case "asahiRule":
      return {
        textColor: "text-complementary-500",
        bgColor: "bg-complementary-100",
        borderColor: "border-complementary-500",
        hoverColor: "hover:bg-complementary-100",
      };
    case "textlint":
      return {
        textColor: "text-green-500",
        bgColor: "bg-green-100",
        borderColor: "border-green-500",
        hoverColor: "hover:bg-green-100",
      };
  }
}
