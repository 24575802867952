import React from "react";
import type { TypolessError } from "@src/types/editor-response";
import { clsx } from "clsx";
import { getErrorPanelColorStyle } from "./get-error-panel-color-style";

export function OtherRuleText({ error }: { error: TypolessError }) {
  const { borderColor, bgColor } = getErrorPanelColorStyle({ error });
  return (
    <div className="space-y-2">
      <span className={clsx("border-b-[2px]", borderColor, bgColor)}>{error.errorText}</span>
      <p className="whitespace-pre-wrap">{error.message}</p>
    </div>
  );
}
