import React from "react";
import { useProofread } from "@src/hooks/use-proofread";
import { useAlertStore } from "@src/store/alert-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { getEditorText } from "@src/taskpane/proofreading/get-editor-text";
import { ProofreadingStatus } from "@src/types/proofreading-status";

export function FlamingRiskCheckButton() {
  const setErrors = useProofreadingStore((state) => state.setErrors);
  const status = useProofreadingStore((state) => state.status);

  const showAlert = useAlertStore((state) => state.showAlert);

  const { proofread } = useProofread();

  const handleClick = async () => {
    const text = await getEditorText();
    if (text.length <= 0) {
      showAlert({
        alertTitle: "エラー",
        alertBody: `テキストが入力されていません`,
      });
      return;
    }
    setErrors([]);
    await proofread({ runImmediately: true, runFlamingRisk: true });
  };

  return (
    <button
      type="button"
      title="炎上リスク"
      disabled={status === ProofreadingStatus.LOADING}
      className="px-2 rounded border border-orange-500 hover:border-transparent hover:bg-orange-100 disabled:border-orange-500 disabled:bg-transparent disabled:opacity-50"
      onClick={handleClick}
    >
      <div>
        <img src="/assets/fire.svg" width="14" height="14" alt="炎上リスクチェック" />
      </div>
    </button>
  );
}
