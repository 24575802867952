import React from "react";
import { getAsahiRuleSubCategoryJa } from "@src/types/asahi-rule-category";
import { Labels } from "@src/types/editor-response";
import type { TypolessError, IgnoredTypolessError } from "@src/types/editor-response";
import { ErrorPanelType } from "@src/types/error-panel-type";
import { getTextlintCategoryJa } from "@src/types/textlint-category";
import { clsx } from "clsx";
import { MdCircle } from "react-icons/md";
import { getErrorPanelColorStyle } from "./get-error-panel-color-style";
import { TyeErrorText } from "./tye-error-text";

export function LabelName({
  open,
  error,
  panelType,
}: {
  open: boolean;
  error: TypolessError;
  panelType: ErrorPanelType;
}) {
  const { textColor, bgColor } = getErrorPanelColorStyle({ error });
  const getDisplayLabel = (error: TypolessError) => {
    if (error.type === "tye") {
      switch (error.label) {
        case Labels.DELETE_LABEL:
          return "削除候補";
        case Labels.REPLACE_LABEL:
          return "置換候補";
        case Labels.FIRST_ADD_LABEL:
        case Labels.ADD_LABEL:
          return "追加候補";
        default:
          console.error(`想定外のラベルです: ${error.label}`);
          break;
      }
    }
    if (error.type === "asahiRule") {
      return error.categories
        .map((category) => getAsahiRuleSubCategoryJa(category))
        .filter((value) => value)
        .join("・");
    }
    if (error.type === "customRule") {
      if (error.isFuzzyMatch) {
        return "カスタム辞書【もしかして】";
      }
      return "カスタム辞書【完全一致】";
    }
    if (error.type === "textlint") {
      return getTextlintCategoryJa(error.category);
    }
    return "";
  };

  if (open) {
    return (
      <div className="flex space-x-2">
        <div className={clsx("flex text-[#EC0000] items-center p-1.5 group-hover/panel:bg-white", bgColor)}>
          <MdCircle className={clsx("h-2 w-2", textColor)} />
          <span className={clsx("font-bold", textColor)}>{getDisplayLabel(error)}</span>
        </div>
        {panelType === ErrorPanelType.IGNORED && (
          <div className="flex items-center text-xs font-bold text-neutral-600">
            {error.type === "asahiRule" && (error as IgnoredTypolessError).isPermanently ? "永続無視" : "一時無視"}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="flex space-x-2">
      <div className={clsx("flex items-center px-2 space-x-2", bgColor)}>
        <MdCircle className={clsx("h-2 w-2", textColor)} />
        {error.type === "tye" ? <TyeErrorText error={error} /> : <p>{error.errorText}</p>}
        <div>-</div>
        <div className={clsx("font-bold", textColor)}>{getDisplayLabel(error)}</div>
      </div>
      {panelType === ErrorPanelType.IGNORED && (
        <div className="flex items-center text-xs font-bold text-neutral-600">
          {error.type === "asahiRule" && (error as IgnoredTypolessError).isPermanently ? "永続無視" : "一時無視"}
        </div>
      )}
    </div>
  );
}
