import React from "react";
import { MdHelp } from "react-icons/md";

export function HelpButton() {
  return (
    <button
      onClick={() => {
        window.open(`${process.env.TYPOLESS_WEB_URL}/guide/word-addin/how-to-use`, "_blank");
      }}
    >
      <MdHelp size={24} className="text-neutral-900 hover:text-neutral-500" />
    </button>
  );
}
