import moji from "moji";
/**
 * 校正対象の文字が何番目にくるか算出する
 * 「あ」が校正対象になった場合に、上から数えて何番目の「あ」なのかを算出する
 * 全角と半角に変換している理由は、
 * 置換やカーソルの移動に使っているsearchメソッドは全角と半角の区別ができないため
 *
 * @param {string} text
 * @param {string} previousText
 * @return {number}
 */
export function getOrderNo({ text, previousText }: { text: string; previousText: string }): number {
  if (!isConversionTarget(text)) {
    return previousText.split(text).length - 1;
  }
  const hankaku = moji(text)
    .convert("ZS", "HS") // 全角スペース -> 半角スペース
    .convert("ZK", "HK") // 全角カナ -> 半角カナ
    .convert("ZE", "HE") // 全角記号 -> 半角記号
    .toString();
  const zenkaku = moji(text)
    .convert("HS", "ZS") // 半角スペース -> 全角スペース
    .convert("HK", "ZK") // 半角カナ -> 全角カナ
    .convert("HE", "ZE") // 半角記号 -> 全角記号
    .toString();

  if (hankaku === zenkaku) {
    return previousText.split(text).length - 1;
  }
  return previousText.split(hankaku).length - 1 + previousText.split(zenkaku).length - 1;
}

/**
 * 全角、半角に変換するか判定する
 *
 * @param {string} chars
 * @return {boolean}
 */
function isConversionTarget(chars: string): boolean {
  // 検索する時にノーブレークスペースは全角と半角で区別されてしまうため変換対象外とする
  const UNICODE_NBSP = 160;
  const nonTargetCharCodes = [UNICODE_NBSP];

  return [...chars].some((char) => !nonTargetCharCodes.includes(char.charCodeAt(0)));
}
