import type { InputTextStates } from "@src/store/proofreading-store";
import { focusErrorPanel } from "@src/taskpane/error-panels/error-panel/focus-error-panel";
import type { TypolessError } from "@src/types/editor-response";

export async function focusNextErrorPanel({
  visibleErrors,
  id,
  setSelectedError,
  inputTextStates,
}: {
  visibleErrors: TypolessError[];
  id: string;
  setSelectedError: (error: TypolessError) => void;
  inputTextStates: InputTextStates[];
}) {
  const index = visibleErrors.findIndex((error) => error.id === id);
  if (index !== -1 && index < visibleErrors.length - 1) {
    const nextError = visibleErrors[index + 1];
    await focusErrorPanel({ targetError: nextError, setSelectedError, behavior: "smooth", inputTextStates });
  }
}
