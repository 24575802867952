import React from "react";
import { Field, Label } from "@headlessui/react";
import clsx from "clsx";

export function ColoredToggleButtonWithSettings({
  children,
  text,
  id,
  areaDecoration,
}: Readonly<{
  children: React.ReactNode;
  text: string;
  id: string;
  areaDecoration: string;
}>) {
  return (
    <div
      id={id}
      className={clsx("flex flex-row items-center gap-1 rounded p-1.5 justify-between w-40", areaDecoration)}
    >
      <Field>
        <div className="flex items-center">
          <Label className="group relative ml-1 min-w-9 font-bold text-sm">{text}</Label>
        </div>
      </Field>
      {children}
    </div>
  );
}
