export const UserPlanType = {
  NO_PLAN: undefined,
  BTOC_STANDARD: "btoc_standard",
  BTOC_PREMIUM: "btoc_premium",
  BTOC_PREMIUM_PLUS: "btoc_premium_plus",
  BTOB_ENTERPRISE: "btob_enterprise",
  BTOB_ENTERPRISE_PLUS: "btob_enterprise_plus",
} as const;

export type UserPlanType = (typeof UserPlanType)[keyof typeof UserPlanType];
