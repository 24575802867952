import React from "react";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { useVisibleErrors } from "@src/store/use-visible-errors";
import { focusNextErrorPanel } from "@src/taskpane/error-panels/error-panel/focus-next-error-panel";
import type { TypolessError } from "@src/types/editor-response";
import { MdVisibilityOff } from "react-icons/md";

export function IgnoreButton({ error }: { error: TypolessError }) {
  const { ignoreError } = useProofreadingStore();
  const setSelectedError = useProofreadingStore((state) => state.setSelectedError);
  const inputTextStates = useProofreadingStore((state) => state.inputTextStates);

  const { visibleErrors } = useVisibleErrors();
  return (
    <MdVisibilityOff
      className="h-6 w-6 text-[#BDBDBD]"
      onClick={async (e) => {
        e.stopPropagation();
        ignoreError(error, false);
        await focusNextErrorPanel({ visibleErrors, id: error.id, setSelectedError, inputTextStates });
      }}
    />
  );
}
