export const AsahiRuleCategory = {
  // 表現
  EXPRESSION: "expression",
  // 誤用
  MISUSE: "misuse",
  // 注意
  COUTION: "coution",
  // 入力/変換
  INPUT_ERRORS: "inputErrors",
  // 固有名詞/専門用語
  PROPER_NOUNS: "properNouns",
} as const;

export const AsahiRuleCategoryJa = {
  EXPRESSION: "表現",
  MISUSE: "誤用",
  COUTION: "注意",
  INPUT_ERRORS: "入力/変換",
  PROPER_NOUNS: "固有名詞",
} as const;

export type AsahiRuleCategory = (typeof AsahiRuleCategory)[keyof typeof AsahiRuleCategory];

export type AsahiRuleCategoryJa = (typeof AsahiRuleCategoryJa)[keyof typeof AsahiRuleCategoryJa];

export const AsahiRuleSubCategory = {
  // 不適切な表現
  INAPPROPRIATE_EXPRESSIONS: "inappropriateExpressions",
  // 表外字
  NON_JOYO_KANJI: "nonJoyoKanji",
  // 表外音訓
  NON_JOYO_READING: "nonJoyoReading",
  // 字体
  CHARACTER_FORM: "characterForm",
  // 交ぜ書き
  MIXED_SCRIPT: "mixedScript",
  // 漢字使用可
  KANJI_ALLOWED: "kanjiAllowed",
  // 数字書式
  NUMERIC_FORMAT: "numericFormat",
  // 誤用
  MISUSE: "misuse",
  // 慣用表現
  IDIOMATIC_EXPRESSION: "idiomaticExpression",
  // 通称
  COMMON_NAME: "commonName",
  // カタカナ表記注意
  KATAKANA_CAUTION: "katakanaCaution",
  // 仮名注意
  KANA_CAUTION: "kanaCaution",
  // 文字種注意
  CHARACTER_TYPE_CAUTION: "characterTypeCaution",
  // 同音異義など注意
  HOMONYM: "homonym",
  // 敬語注意
  HONORIFICS_CAUTION: "honorificsCaution",
  // かな読み
  KANA_READING: "kanaReading",
  // 全角
  ZENKAKU: "zenkaku",
  // 入力・変換ミス
  INPUT_CONVERSION_ERROR: "inputConversionError",
  // ダブり表現
  REDUNDANT_EXPRESSION: "redundantExpression",
  // 脱字
  OMITTED_CHARACTER: "omittedCharacter",
  // 地名注意
  PLACE_NAME_CAUTION: "placeNameCaution",
  // 固有名詞注意
  PROPER_NOUN_CAUTION: "properNounCaution",
  // 専門用語
  TECHNICAL_TERM: "technicalTerm",
  // // 縦書き (レスポンスには存在するが、特殊なため利用しないことになった)
  VERTICAL_WRITING: "verticalWriting",
  // // 朝日新聞ルール (レスポンスには存在するが、特殊なため利用しないことになった)
  ASAHI_RULE: "asahiRule",
} as const;

export const AsahiRuleSubCategoryJa = {
  // 不適切な表現
  INAPPROPRIATE_EXPRESSIONS: "不適切な表現",
  // 表外字
  NON_JOYO_KANJI: "表外字",
  // 表外音訓
  NON_JOYO_READING: "表外音訓",
  // 字体
  CHARACTER_FORM: "字体",
  // 交ぜ書き
  MIXED_SCRIPT: "交ぜ書き",
  // 漢字使用可
  KANJI_ALLOWED: "漢字使用可",
  // 数字書式
  NUMERIC_FORMAT: "数字書式",
  // 誤用
  MISUSE: "誤用",
  // 慣用表現
  IDIOMATIC_EXPRESSION: "慣用表現",
  // 通称
  COMMON_NAME: "通称",
  // カタカナ表記注意
  KATAKANA_CAUTION: "カタカナ表記注意",
  // 仮名注意
  KANA_CAUTION: "仮名注意",
  // 文字種注意
  CHARACTER_TYPE_CAUTION: "文字種注意",
  // 同音異義など注意
  HOMONYM: "同音異義など注意",
  // 敬語注意
  HONORIFICS_CAUTION: "敬語注意",
  // かな読み
  KANA_READING: "かな読み",
  // 全角
  ZENKAKU: "全角",
  // 入力・変換ミス
  INPUT_CONVERSION_ERROR: "入力・変換ミス",
  // ダブり表現
  REDUNDANT_EXPRESSION: "ダブり表現",
  // 脱字
  OMITTED_CHARACTER: "脱字",
  // 地名注意
  PLACE_NAME_CAUTION: "地名注意",
  // 固有名詞注意
  PROPER_NOUN_CAUTION: "固有名詞注意",
  // 専門用語
  TECHNICAL_TERM: "専門用語",
  // // 縦書き (レスポンスには存在するが、特殊なため利用しないことになった)
  // VERTICAL_WRITING: "縦書き",
  // // 朝日新聞ルール (レスポンスには存在するが、特殊なため利用しないことになった)
  // ASAHI_RULE: "朝日新聞ルール",
} as const;

export type AsahiRuleSubCategory = (typeof AsahiRuleSubCategory)[keyof typeof AsahiRuleSubCategory];
export type AsahiRuleSubCategoryJa = (typeof AsahiRuleSubCategoryJa)[keyof typeof AsahiRuleSubCategoryJa];

export function getAsahiRuleSubCategoryJa(category: string): string {
  const asahiRuleSubCategoryKey = Object.keys(AsahiRuleSubCategory).find(
    (key) => AsahiRuleSubCategory[key as keyof typeof AsahiRuleSubCategory] === category
  );

  if (asahiRuleSubCategoryKey) {
    return AsahiRuleSubCategoryJa[asahiRuleSubCategoryKey as keyof typeof AsahiRuleSubCategoryJa];
  }

  return category;
}
