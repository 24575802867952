import type { AsahiRuleCategory } from "@src/types/asahi-rule-category";
import type { TextlintCategory } from "@src/types/textlint-category";

export type EditorResponse = {
  message: string;
  error: string;
  asahiRule?: {
    errors: AsahiRule[];
  };
  customRule?: {
    errors: CustomRule[];
  };
  riskRule?: {
    errors: AsahiRule[];
  };
  textlint?: {
    errors: Textlint[];
  };
  flamingRisk?: {
    risks: FlamingRisk[];
  };
  tye?: {
    predictions: Prediction[];
    chunks: { [key: string]: string };
  };
};

export type Prediction = {
  char: string;
  label: Labels;
  score: number;
  candidate: string | null;
  chunk_id: number;
};

export type CustomRule = {
  cand: string;
  error_text: string;
  message: string;
  range: number[];
  is_fuzzy_match: boolean;
};

export type AsahiRule = {
  cand: string[];
  rule_id?: string;
  error_text: string;
  message: string;
  range: number[];
  error_categories: {
    en: AsahiRuleCategory;
    ja: string;
  }[];
};

export type Textlint = {
  cand: string;
  error_text: string;
  message: string;
  range: number[];
  error_category: TextlintCategory;
};

export type FlamingRisk = {
  error_text: string;
  range: number[];
};

export type TyeError = {
  type: "tye";
  texts: Texts[];
  id: string;
  indexes: number[];
  label: Labels;
  candidate: string;
  errorText: string;
  chunk: string;
  message: string;

  prediction?: Prediction;
  revisedChunk?: string;
};

export type CustomRuleError = {
  type: "customRule";
  id: string;
  indexes: number[];
  candidate: string;
  errorText: string;
  message: string;
  isFuzzyMatch: boolean;
};

export type AsahiRuleError = {
  type: "asahiRule";
  id: string;
  indexes: number[];
  ruleId?: string;
  candidate: string;
  errorText: string;
  message: string;
  categories: AsahiRuleCategory[];
};

export type TextlintError = {
  type: "textlint";
  id: string;
  indexes: number[];
  candidate: string;
  errorText: string;
  message: string;
  category: TextlintCategory;
};

export type FlamingRiskError = {
  type: "flamingRisk";
  id: string;
  indexes: number[];
  candidate: string;
  errorText: string;
  message: string;
};

export type TypolessError = TyeError | CustomRuleError | AsahiRuleError | TextlintError | FlamingRiskError;
export type IgnoredTypolessError = TypolessError & { isPermanently: boolean };

type Texts = {
  char: string;
  isError: boolean;
};

export const Labels = {
  NO_ERROR_LABEL: "O",
  FIRST_ADD_LABEL: "FIRSTADD",
  ADD_LABEL: "ADD",
  DELETE_LABEL: "DELETE",
  REPLACE_LABEL: "REPLACE",
} as const;
export type Labels = (typeof Labels)[keyof typeof Labels];
