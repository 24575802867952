import React, { useState } from "react";
import { AsahiRuleCategorySettingsModal } from "@src/taskpane/editor-config/asahi-rule-category-settings-modal";
import { MdOutlineSettings } from "react-icons/md";

export function AsahiRuleCategorySettingsButton() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <button
        id="asahi-rule-category-settings"
        title="ルール辞書カテゴリー設定"
        type="button"
        className="flex text-neutral-900 hover:text-neutral-500 disabled:text-neutral-500"
      >
        <MdOutlineSettings className="size-4" onClick={openModal} />
      </button>
      <AsahiRuleCategorySettingsModal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
}
