import type { ServiceType } from "@src/types/service-type";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import type { Dictionary } from "../types/dictionary";
import { UserPlanType } from "../types/user-plan-type";

type UserStore = {
  serviceType: ServiceType;
  plan: UserPlanType;
  dictionaries: Dictionary[];
  selectedDictId: string;

  setServiceType: (serviceType: ServiceType) => void;
  setPlan: (plan: UserPlanType) => void;
  setDictionaries: (dictionaries: Dictionary[]) => void;
  setSelectedDictId: (dictId: string) => void;
  clearUser: () => void;
};

const initialUserState = {
  serviceType: undefined,
  plan: UserPlanType.NO_PLAN,
  dictionaries: [],
  selectedDictId: "",
};

const clearUserState = {
  serviceType: undefined,
  plan: UserPlanType.NO_PLAN,
  dictionaries: [],
};

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      ...initialUserState,
      setServiceType: (serviceType) => set(() => ({ serviceType })),
      setPlan: (plan) => set(() => ({ plan })),

      setDictionaries: (dictionaries) => set(() => ({ dictionaries })),
      setSelectedDictId: (selectedDictId) => set(() => ({ selectedDictId })),

      clearUser: () => set(() => ({ ...clearUserState })),
    }),
    {
      name: "user",
    },
  ),
);
