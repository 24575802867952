import type { InputTextStates } from "@src/store/proofreading-store";

/**
 * 指定したindexよりも前の文字列を取得する
 *
 * @param {InputTextStates[]} inputTextStates
 * @param {number} id
 * @return {string}
 */
export function getPreviousText(inputTextStates: InputTextStates[], id: string): string {
  const filteredInputTextStates = inputTextStates.find(({ errorPanelId }) => errorPanelId === id);
  const previousTextStatuses = inputTextStates.slice(0, filteredInputTextStates.index);
  let previousText = "";
  previousTextStatuses.forEach((status) => {
    previousText += status.char;
  });
  return previousText;
}
